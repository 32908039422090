import React from "react"
import { Button, Form, Row, Col } from "react-bootstrap"

export default class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <Form
        onSubmit={this.submitForm}
        action="https://formspree.io/mzbjonkp"
        method="POST"
      >
        <Form.Group>
          <Row>
            <Col>
              <Form.Control placeholder="First name" name="First Name" />
            </Col>
            <Col>
              <Form.Control placeholder="Last name" name="Last Name" />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Control type="email" placeholder="Email" name="E-mail" />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control
            placeholder="Message"
            as="textarea"
            rows="3"
            name="Message"
          />
        </Form.Group>
        {status === "SUCCESS" ? (
          <p className="status">Thanks I'll be in contact with you soon</p>
        ) : (
          <Button variant="primary" type="submit" className="contactButton">
            Send Message{" "}
          </Button>
        )}
        {status === "ERROR" && (
          <p className="status">Please fill out all the fields</p>
        )}
      </Form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}

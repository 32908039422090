import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaPhoneAlt, FaEnvelope, FaLocationArrow } from "react-icons/fa"

const ContactInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { templateKey: { eq: "contactinformation" } }
      ) {
        frontmatter {
          email
          phoneNumber
          location
        }
      }
    }
  `)

  return (
    <ul style={{ listStyle: "none", paddingInlineStart: 0 }}>
      <li>
        {" "}
        <FaEnvelope />{" "}
        <a href={`mailto:${data.markdownRemark.frontmatter.email}`}>
          {" "}
          {data.markdownRemark.frontmatter.email}
        </a>
      </li>
      <li>
        <FaPhoneAlt />{" "}
        <a href={`tel:${data.markdownRemark.frontmatter.phoneNumber}`}>
          {" "}
          {data.markdownRemark.frontmatter.phoneNumber}
        </a>
      </li>
      <li>
        <FaLocationArrow />{" "}
        <p style={{ display: "inline" }}>
          {" "}
          {data.markdownRemark.frontmatter.location}
        </p>
      </li>
    </ul>
  )
}

export default ContactInfo

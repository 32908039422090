import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import MyForm from "../components/myForm"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import ContactInfo from "../components/contactInfo"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "socialmedialinks" } }) {
        frontmatter {
          facebookLink
          instagramLink
          linkedInLink
        }
      }
    }
  `)

  useEffect(() => {
    AOS.init({ disable: "phone" })
  }, [])

  return (
    <>
      <section id="contactme">
        <Row className="center">
          <Col
            xs={12}
            md={6}
            lg={4}
            className="col_padding"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h3>Get In Touch With Me</h3>
            <br />
            <MyForm />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={4}
            className="contactInfo col_padding"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <ContactInfo />
          </Col>
        </Row>
      </section>
      <section id="socialmedia">
        <Row style={{ textAlign: "center" }}>
          <Col>
            <a
              href={data.markdownRemark.frontmatter.facebookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href={data.markdownRemark.frontmatter.instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href={data.markdownRemark.frontmatter.linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Footer

//accordion portfolio filter

import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import AOS from "aos"
import "aos/dist/aos.css"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Card, Accordion, Button, Nav } from "react-bootstrap"

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "post" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              category
              thumbnail {
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const [toggle, setToggle] = useState(false)
  const [filter, setFilter] = useState("All")
  const [projects, setProjects] = useState([])

  useEffect(() => {
    setProjects(data.allMarkdownRemark.edges)
  }, [data.allMarkdownRemark.edges])

  useEffect(() => {
    setProjects([])
    const filtered = data.allMarkdownRemark.edges.map(edge => ({
      ...edge,
      filtered: edge.node.frontmatter.category.includes(filter),
    }))

    setProjects(filtered)
  }, [filter, data.allMarkdownRemark.edges])

  useEffect(() => {
    AOS.init({ disable: "phone" })
  }, [])

  const arrayLenght = data.allMarkdownRemark.edges.length

  const ceramicsArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Ceramics")
  })

  const booksArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Books")
  })

  const drawingArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Drawing")
  })

  const embroideryArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Embroidery")
  })

  const collaborationArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Collaboration")
  })

  const otherArray = projects.filter(function (project) {
    return project.node.frontmatter.category.includes("Other")
  })

  return (
    <section id="portfolio" data-aos="fade-left" data-aos-duration="1000">
      <h2> Portfolio</h2>
      <Nav activeKey="All">
        <Nav.Item>
          <Nav.Link eventKey="All" onSelect={() => setFilter("All")}>
            <p className={filter === "All" ? "active" : ""}>All</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Ceramics" onSelect={() => setFilter("Ceramics")}>
            <p className={filter === "Ceramics" ? "active" : ""}>Ceramics</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Books" onSelect={() => setFilter("Books")}>
            <p className={filter === "Books" ? "active" : ""}>Books</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Drawing" onSelect={() => setFilter("Drawing")}>
            <p className={filter === "Drawing" ? "active" : ""}>Drawing</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="Embroidery"
            onSelect={() => setFilter("Embroidery")}
          >
            <p className={filter === "Embroidery" ? "active" : ""}>
              Embroidery
            </p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="Collaboration"
            onSelect={() => setFilter("Collaboration")}
          >
            <p className={filter === "Collaboration" ? "active" : ""}>
              Collaboration
            </p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Other" onSelect={() => setFilter("Other")}>
            <p className={filter === "Other" ? "active" : ""}>Other</p>{" "}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {filter === "All" ? (
        <>
          {" "}
          <Row className="center">
            {projects.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {arrayLenght >= 4 ? (
                  projects.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Ceramics" ? (
        <>
          {" "}
          <Row className="center">
            {ceramicsArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {ceramicsArray.length >= 4 ? (
                  ceramicsArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Books" ? (
        <>
          {" "}
          <Row className="center">
            {booksArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {booksArray.length >= 4 ? (
                  booksArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Drawing" ? (
        <>
          {" "}
          <Row className="center">
            {drawingArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {drawingArray.length >= 4 ? (
                  drawingArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Embroidery" ? (
        <>
          {" "}
          <Row className="center">
            {embroideryArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {embroideryArray.length >= 4 ? (
                  embroideryArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Collaboration" ? (
        <>
          {" "}
          <Row className="center">
            {collaborationArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {collaborationArray.length >= 4 ? (
                  collaborationArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : filter === "Other" ? (
        <>
          {" "}
          <Row className="center">
            {otherArray.slice(0, 3).map((edge, index) => {
              return edge.filtered === true ? (
                <Col xs={12} md={4} className="col_padding" key={index}>
                  <Link
                    to={`/portfolio/${edge.node.fields.slug}`}
                    state={{
                      modal: true,
                      noScroll: false,
                    }}
                  >
                    {edge.node.frontmatter.thumbnail.image && (
                      <Card>
                        <Img
                          className="imgFit"
                          fluid={
                            edge.node.frontmatter.thumbnail.image
                              .childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.thumbnail.alt}
                        />
                        <div className="overlay">
                          <div className="text">
                            {edge.node.frontmatter.title}
                          </div>
                        </div>
                      </Card>
                    )}
                  </Link>
                </Col>
              ) : (
                ""
              )
            })}
          </Row>
          <Accordion>
            {toggle === false ? (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(true)}
              >
                Load More{" "}
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle
                eventKey="0"
                className="customButton"
                as={Button}
                onClick={() => setToggle(false)}
              >
                Show Less{" "}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Row className="center">
                {otherArray.length >= 4 ? (
                  otherArray.slice(3).map((edge, index) => {
                    return edge.filtered === true ? (
                      <Col xs={12} md={4} className="col_padding" key={index}>
                        <Link
                          to={`/portfolio/${edge.node.fields.slug}`}
                          state={{
                            modal: true,
                            noScroll: false,
                          }}
                        >
                          {edge.node.frontmatter.thumbnail.image && (
                            <Card>
                              <Img
                                className="imgFit"
                                fluid={
                                  edge.node.frontmatter.thumbnail.image
                                    .childImageSharp.fluid
                                }
                                alt={edge.node.frontmatter.thumbnail.alt}
                              />
                              <div className="overlay">
                                <div className="text">
                                  {edge.node.frontmatter.title}
                                </div>
                              </div>
                            </Card>
                          )}
                        </Link>
                      </Col>
                    ) : (
                      ""
                    )
                  })
                ) : (
                  <p className="marginTop">
                    {" "}
                    There aren't any more projects in this category for now
                  </p>
                )}
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </>
      ) : (
        ""
      )}
    </section>
  )
}

export default Portfolio

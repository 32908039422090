//media accordion

import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import AOS from "aos"
import "aos/dist/aos.css"
import { Row, Col, Card, Accordion, Button } from "react-bootstrap"

const Media = () => {
  useEffect(() => {
    AOS.init({ disable: "phone" })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "media" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  const [toggle, setToggle] = useState(false)

  const arrayLenght = data.allMarkdownRemark.edges.length

  return (
    <section id="media" data-aos="fade-right" data-aos-duration="1000">
      <h2>Media</h2>

      <Row>
        <Col xs={12} md={6} className="col_padding">
          <ul className="media-list">
            {data.allMarkdownRemark.edges.slice(0, 3).map((edge, index) => {
              return (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: edge.node.html,
                  }}
                ></li>
              )
            })}
          </ul>
        </Col>
      </Row>

      <Accordion>
        {toggle === false ? (
          <Accordion.Toggle
            eventKey="0"
            className="customButton"
            as={Button}
            onClick={() => setToggle(true)}
          >
            Load More{" "}
          </Accordion.Toggle>
        ) : (
          <Accordion.Toggle
            eventKey="0"
            className="customButton"
            as={Button}
            onClick={() => setToggle(false)}
          >
            Show Less
          </Accordion.Toggle>
        )}
        <Accordion.Collapse eventKey="0">
          <Row className="center">
            {arrayLenght >= 4 ? (
              data.allMarkdownRemark.edges.slice(3).map((edge, index) => {
                return (
                  <Col xs={12} md={4} className="col_padding" key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={edge.node.frontmatter.link}
                    >
                      {edge.node.frontmatter.thumbnail.image && (
                        <Card>
                          <Img
                            className="imgFit"
                            fluid={
                              edge.node.frontmatter.thumbnail.image
                                .childImageSharp.fluid
                            }
                            alt={edge.node.frontmatter.thumbnail.alt}
                          />
                        </Card>
                      )}
                    </a>
                  </Col>
                )
              })
            ) : (
              <p style={{ marginTop: "1em" }}>
                {" "}
                There aren't any more medias for now
              </p>
            )}
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </section>
  )
}

export default Media

import React from "react"
import Head from "../components/head"
import Logo from "../sections/logo"
import AboutMe from "../sections/about"
import Portfolio from "../sections/portfolio"
import Media from "../sections/media"
import Footer from "../sections/footer"

const Home = () => {
  return (
    <>
      <Head title="" />
      <Logo />
      <AboutMe />
      <Portfolio />
      <Media />
      <hr className="gradientDash" />
      <Footer />
    </>
  )
}

export default Home

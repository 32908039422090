import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import AOS from "aos"
import "aos/dist/aos.css"

const AboutMe = () => {
  useEffect(() => {
    AOS.init({ disable: "phone" })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "aboutme" } }) {
        html
        frontmatter {
          templateKey
          cv_pdf {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <section id="aboutme">
      <Row>
        <Col
          xs={12}
          lg={6}
          data-aos="fade-right"
          data-aos-duration="1000"
          className="col_padding"
        >
          <h2>About Me</h2>
          {/* <article>{data.markdownRemark.frontmatter.paragraph}</article> */}
          <div
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          ></div>
          <br />
          <span className="link">
            <a
              href={data.markdownRemark.frontmatter.cv_pdf.publicURL}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Curriculum Vitae
            </a>
          </span>
        </Col>
        {/* <Col xs={12} md={6}>
          <div className="dots"></div>
        </Col> */}
      </Row>
    </section>
  )
}

export default AboutMe
